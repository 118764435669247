<template>
    <v-layout row wrap>
        <v-flex xs12>
            <v-card>
                <table class="primary--border v-sheet theme--light" style="border: 1px solid black; width: 100%">
                    <thead>
                    <tr>
                        <th class="text-xs-left tbs"><strong>Batch</strong></th>
                        <th class="text-xs-center tbs"><strong>Grade</strong></th>
                        <th class="text-xs-center tbs"><strong>Section</strong></th>
                        <th class="text-xs-center tbs"><strong>Roll</strong></th>
                        <th class="text-xs-right tbs"><strong>Action</strong></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(enroll, i) in student.data.batch_records" :key="i">
                        <td class="text-xs-left tbs">
                            <strong>{{ enroll.academic.batch }}</strong>
                            &nbsp;&nbsp;
                            <v-icon small v-if="enroll.is_current===1 && batch.id===enroll.academic.batch_id"
                                    color="teal">
                                check_circle
                            </v-icon>
                            <br>
                            <span class="remarks" v-if="enroll.remarks"><small class="text--lighten-1">{{enroll.remarks}}</small></span>
                        </td>
                        <td class="text-xs-center tbs"><strong>{{ enroll.academic.grade }}</strong></td>
                        <td class="text-xs-center tbs"><strong>{{ enroll.academic.section }}</strong></td>
                        <td class="text-xs-center tbs"><strong>{{ enroll.roll }}</strong></td>
                        <td width="260px" class="text-xs-right tbs">
                            <edit-button
                                    v-if="enroll.is_current===1 && batch.id===enroll.academic.batch_id"
                                    permission="student-update"
                                    @agree="edit"/> &nbsp;
                            <view-button label="Promote" @action="promoteMe=!promoteMe,demoteMe=false"
                                         v-if="enroll.is_current===1 && batch.id===enroll.academic.batch_id"
                                         permission="student-promote"
                                         :icon="promoteMe?'close':'add'"></view-button>
&nbsp;
                            <view-button label="Demote" icon="remove" color="error"
                                         v-if="enroll.is_current===1 && batch.id===enroll.academic.batch_id"
                                         @action="demoteMe=!demoteMe,promoteMe=false"
                                         permission="student-promote"/>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <promote-student :isDemote="demoteMe"
                                 :isPromote="promoteMe"
                                 @onPromote="getStudentInfo('/api/student/'+($route.params.id)),demoteMe=false,promoteMe=false"
                                 :student="student"
                                 v-if="(promoteMe===true || demoteMe===true) && $auth.can('student-promote')"/>
            </v-card>
        </v-flex>

        <v-dialog v-model="form.dialog" persistent max-width="350px">
            <v-card>
                <v-card-title class="primary white--text">
                    <span class="title">Add/Update</span>
                </v-card-title>
                <v-card-text class="pb-1">
                    <v-form ref="form" @submit.prevent="save"
                            @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                            lazy-validation>
                        <v-container grid-list-md pa-0 pt-2>
                            <v-layout row wrap pa-0>
                                <v-flex xs12>
                                    <v-text-field outlined dense autocomplete="off" label="Batch*" disabled required class="pa-0"
                                                  v-model="form.batch_name" name="name"
                                                  :error-messages="form.errors.get('name')"/>
                                </v-flex>
                                <v-flex xs6>
                                    <v-text-field outlined dense autocomplete="off" label="Roll no*" required class="pa-0"
                                                  v-model="form.roll_no" name="name"
                                                  :error-messages="form.errors.get('name')"/>
                                </v-flex>
                                <v-flex xs6>
                                    <v-select outlined dense :items="sections" :disabled="sections.length<1" required class="pa-0" label="Section*"
                                              v-model="form.section_id"
                                              :error-messages="form.errors.get('section_id')"/>
                                </v-flex>
                                <v-flex xs12>
                                    <v-textarea
                                            outlined dense
                                            height="80"
                                            name="input-7-4"
                                            v-model="form.remarks"
                                            label="Remarks"
                                    ></v-textarea>
                                </v-flex>
                            </v-layout>
                        </v-container>

                    </v-form>

                    <!--<v-flex xs12>-->
                    <!--<template>-->
                    <!--<div style="color:red;">-->
                    <!--<v-icon small color="orange">error_outline</v-icon>-->
                    <!--<span style="color:orange"> Please Note</span>-->
                    <!--<strong></strong>-->
                    <!--<p style="line-height: 10px">-->
                    <!--<small style="color:#666;font-size: 10px;">-->
                    <!--The rank number provided should be a in hierarchical order <br> according to-->
                    <!--the grade level.-->
                    <!--</small>-->
                    <!--</p>-->
                    <!--</div>-->
                    <!--</template>-->
                    <!--</v-flex>-->
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" outlined @click="form.dialog = false, form.reset()">Close</v-btn>
                    <v-btn color="success" outlined @click="save">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-layout>

</template>
<script>
    import {mapState, mapActions, mapMutations} from 'vuex'
    import Form from "../../../../../library/Form";
    import PromoteStudent from './../../Promote/promote.vue';

    export default {
        components: {PromoteStudent},
        data: () => ({
            promoteMe: false,
            demoteMe: false,
            transport: {},
            activeBtn: 0,
            enrolls: [],
            sections: [],
            headers: [
                {text: '#', align: 'left', value: 'id', width: 50, sortable: false},
                {text: 'Batch', align: 'left', value: 'batch', sortable: false},
                {text: 'Grade', align: 'center', value: 'grade', width: 80, sortable: false},
                {text: 'Section', align: 'left', value: 'section', sortable: false},
                {text: 'Roll', align: 'left', value: 'roll', sortable: false},
                {text: 'Action', align: 'right', sortable: false, width: 280}
            ],
            form: new Form({
                roll_no: '',
                // id: '',
                section_id: '',
                batch_name: '',
                grade_id: '',
                batch_id: '',
                remarks: ''
            }, '/api/enroll')
        }),
        computed: {
            ...mapState(['student', 'batch'])
        },
        watch: {},
        mounted() {
        },
        methods: {
            ...mapActions(['setStudent', 'getStudentInfo']),
            save() {
                this.$rest.put('/api/enroll/' + this.student.data.enroll_id, this.form.data()).then(res => {
                    this.$events.fire('notification', {message: res.data.message});
                    this.form.dialog = false;
                    //refetch student.
                    this.$rest.get('/api/student/' + +this.$route.params.id).then(({data}) => {
                        this.setStudent(data);
                    });
                });
            },
            edit() {
                this.form.dialog = true;
                let $this = this;
                $this.form.roll_no = this.student.data.roll;
                $this.form.section_id = parseInt(this.student.data.section_id);
                // $this.form.id = ;
                this.student.data.batch_records.map(function (data) {
                    // $this.form.batch_name = data.academic.batch;
                    if (parseInt($this.student.data.batch_id) == data.academic.batch_id) {
                        $this.form.batch_name = data.academic.batch;
                        $this.form.batch_id = data.academic.batch_id;
                        $this.form.grade_id = data.academic.grade_id;
                        $this.form.remarks = data.remarks;
                        $this.form.section_id = data.academic.section_id;
                    }
                });
                this.$rest.get('/api/section?grade=' + $this.form.grade_id + '&batch=' + $this.form.batch_id).then(({data}) => {
                    this.sections = data.data.map((section) => {
                        return {value: section.id, text: section.name}
                    });
                });
                // this.form.edit($this.form.data());
            }
        }
    }
</script>

<style>
    .atom-spinner, .atom-spinner * {
        box-sizing: border-box;
    }
    .general {
        width: 100%;
    }
    table {
        border-collapse: collapse;
    }
    .tbs {
        border: 1px solid #cccccc;
        text-align: center;
    }
    .atom-spinner {
        height: 60px;
        width: 60px;
        overflow: hidden;
    }

    .atom-spinner .spinner-inner {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
    }

    .atom-spinner .spinner-circle {
        display: block;
        position: absolute;
        color: #ff1d5e;
        font-size: calc(60px * 0.24);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .atom-spinner .spinner-line {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        animation-duration: 1s;
        border-left-width: calc(60px / 25);
        border-top-width: calc(60px / 25);
        border-left-color: #ff1d5e;
        border-left-style: solid;
        border-top-style: solid;
        border-top-color: transparent;
    }

    .atom-spinner .spinner-line:nth-child(1) {
        animation: atom-spinner-animation-1 1s linear infinite;
        transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
    }

    .atom-spinner .spinner-line:nth-child(2) {
        animation: atom-spinner-animation-2 1s linear infinite;
        transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
    }

    .atom-spinner .spinner-line:nth-child(3) {
        animation: atom-spinner-animation-3 1s linear infinite;
        transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
    }

    @keyframes atom-spinner-animation-1 {
        100% {
            transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
        }
    }

    @keyframes atom-spinner-animation-2 {
        100% {
            transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
        }
    }

    @keyframes atom-spinner-animation-3 {
        100% {
            transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
        }
    }
</style>