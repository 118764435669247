<template>
  <v-container
    id="scroll-target"
    grid-list-md
    pa-0
    relative
    class="attendence_view"
    :style="isDemote ? 'border-top:3px solid #ff5252;' : ''"
  >
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="page-container-card primary--border" outlined>
          <br />
          <v-timeline class="user_add_form" align-top dense>
            <v-timeline-item color="red" small>
              <v-layout pt-3>
                <v-flex>
                  <strong
                    >{{ isDemote === true ? "Demote" : "Promote" }} From -
                    <span>{{ batch.name }}</span>
                  </strong>
                  <div class="caption">
                    Select Info from where you want to promote students from.
                  </div>
                  <v-flex pt-3>
                    <v-container style="padding:0" grid-list-md>
                      <v-layout row wrap>
                        <v-flex sm3>
                          <v-select
                            outlined
                            dense
                            :disabled="
                              promote.from.grades.length < 1 ||
                                this.singleStudent
                            "
                            :items="promote.from.grades"
                            class="pa-0"
                            label="Select grade"
                            v-model="promote.from.grade"
                          />
                        </v-flex>
                        <v-flex xs2>
                          <v-select
                            outlined
                            dense
                            :disabled="
                              promote.from.sections.length < 1 ||
                                this.singleStudent
                            "
                            :items="promote.from.sections"
                            class="pa-0"
                            label="Select section"
                            v-model="promote.from.section_id"
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-timeline-item>
            <v-timeline-item color="green" small>
              <v-layout pt-3>
                <v-flex>
                  <strong
                    >{{ isDemote === true ? "Demote" : "Promote" }} To
                    {{ toBatch.length ? " - " + toBatch : "" }}</strong
                  >
                  <div class="caption">
                    Select Info where you want to promote students to.
                  </div>
                  <v-flex pt-3>
                    <v-container style="padding:0" grid-list-md>
                      <v-layout row wrap>
                        <v-flex xs3>
                          <v-select
                            :disabled="toBatches.length < 1"
                            :items="toBatches"
                            @change="getPromoteToGrades()"
                            class="pa-0"
                            label="Select batch"
                            outlined
                            dense
                            v-model="promote.to.batch_id"
                          />
                        </v-flex>
                        <v-flex sm3>
                          <v-select
                            :disabled="toGradeItems.length < 1"
                            @change="getPromoteToSections()"
                            :items="toGradeItems"
                            class="pa-0"
                            label="Select grade"
                            outlined
                            dense
                            v-model="promote.to.grade"
                          />
                        </v-flex>
                        <v-flex xs2>
                          <v-select
                            :disabled="promote.to.sections.length < 1"
                            :items="promote.to.sections"
                            class="pa-0"
                            label="Select section"
                            outlined
                            dense
                            v-model="promote.to.section_id"
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-timeline-item>
          </v-timeline>
          <br />
          <v-container>
            <template>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs6>
                    <v-card outlined class="primary--border">
                      <v-card-title>
                        <h4>
                          {{ isDemote === true ? "Demote" : "Promote" }} From -
                          {{ batch.name }} {{ fromGrades }} {{ fromSection }}
                        </h4>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-list dense>
                        <template v-if="studentListItems.length > 0">
                          <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :options.sync="pagination"
                            :items="studentListItems"
                            hide-default-footer
                            show-select
                            item-key="name"
                            class="elevation-1"
                          >
                            <template v-slot:headers="props">
                              <tr>
                                <th>
                                  <v-checkbox
                                    v-if="!singleStudent"
                                    :input-value="props.all"
                                    :indeterminate="props.indeterminate"
                                    primary
                                    hide-details
                                    @click.stop="toggleAll"
                                  ></v-checkbox>
                                </th>
                                <th
                                  :align="header.align"
                                  v-for="header in props.headers"
                                  :key="header.text"
                                  :class="[
                                    'column sortable',
                                    pagination.descending ? 'desc' : 'asc',
                                    header.value === pagination.sortBy
                                      ? 'active'
                                      : '',
                                  ]"
                                >
                                  {{ header.text }}
                                </th>
                              </tr>
                            </template>
                            <template v-slot:items="props">
                              <tr
                                :active="props.selected"
                                @click="props.selected = !props.selected"
                              >
                                <td>
                                  <v-checkbox
                                    :input-value="props.selected"
                                    primary
                                    hide-details
                                  ></v-checkbox>
                                </td>
                                <td>{{ props.item.name }}</td>
                                <td class="text-xs-right">
                                  {{ props.item.roll }}
                                </td>
                                <td></td>
                              </tr>
                            </template>
                          </v-data-table>
                        </template>
                        <v-btn
                          v-if="
                            studentListItems.length > 0 && promote.to.section_id
                          "
                          @click="promoteStudents"
                          color="green"
                          dark
                          big
                          absolute
                          bottom
                          right
                          fab
                        >
                          <v-icon>check</v-icon>
                        </v-btn>
                      </v-list>
                    </v-card>
                  </v-flex>
                  <v-flex xs6>
                    <v-card outlined class="primary--border">
                      <v-card-title>
                        <h4>
                          {{ isDemote === true ? "Demote" : "Promote" }} To -
                          {{ toBatch }} {{ toGrades }} {{ toSection }}
                        </h4>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-list dense>
                        <template v-if="newEnrolls.length > 0">
                          <v-data-table
                            :headers="headers"
                            :items="newEnrolls"
                            :options.sync="pagination"
                            hide-default-footer
                            item-key="name"
                            class="elevation-1"
                          >
                            <template v-slot:items="props">
                              <tr
                                :active="props.selected"
                                @click="props.selected = !props.selected"
                              >
                                <td>{{ props.item.name }}</td>
                                <td class="text-xs-right">-</td>
                                <td></td>
                              </tr>
                            </template>
                          </v-data-table>
                        </template>
                      </v-list>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </template>
          </v-container>
          <br />
          <br />
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<style scoped></style>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Pagination from "@/library/Mixins";

export default {
  mixins: [Pagination],
  props: {
    student: {
      type: Object,
    },
    isPromote: {
      type: Boolean,
    },
    isDemote: {
      type: Boolean,
    },
  },
  data: () => ({
    props: {},
    rowsPerPageItems: [4, 8, 12],
    pagination: {
      itemsPerPage: 100,
      sortBy: ["roll"],
    },
    student_code: "",
    form: new Form(
      {
        new_section_id: "",
        old_section_id: "",
        students: [],
      },
      "/api/promote-list"
    ),
    batches: [],
    promote: {
      from: {
        section_id: "",
        grades: [],
        sections: [],
        grade: "",
      },
      to: {
        section_id: "",
        batch_id: "",
        grades: [],
        grade: "",
        sections: [],
      },
    },
    selectedStudent: [],
    selected: [],
    headers: [
      { text: "Name", value: "name", align: "left", sortable: false },
      { text: "Roll", value: "roll", sortable: false, align: "right" },
      { text: "", value: "", sortable: false, align: "right" },
    ],
    grade: "",
    grades: [],
    newEnrolls: [],
    grade_request_sent: false,
  }),
  watch: {
    pagination: function() {
      // this.get();
    },
    batch: function() {
      this.promote.from.grade = "";
      this.promote.from.section_id = "";
      this.promote.from.grades = [];
      this.promote.from.sections = [];

      this.promote.to.grade = "";
      this.promote.to.section_id = "";
      this.promote.to.grades = [];
      this.promote.to.sections = [];
      if (!this.grade_request_sent) this.getGrades();
    },
    grade: function() {
      // this.get();
    },
    "promote.from.grade": function() {
      this.getSections();
    },
    "promote.to.section_id": function() {
      this.get();
    },
    "promote.from.section_id": function() {
      this.get();
    },
  },
  computed: {
    ...mapState(["batch"]),
    studentListItems() {
      if (!this.singleStudent) return this.form.items.data;
      else return this.selected;
    },
    singleStudent() {
      return this.student && Object.keys(this.student).length > 0;
    },
    toBatches() {
      let $this = this;
      return this.batches.filter(function(batch) {
        if (
          $this.isDemote &&
          parseInt(batch.value) === parseInt($this.batch.id) &&
          batch.is_current_batch === true
        ) {
          return batch;
        } else if (
          (!$this.isDemote &&
            batch.is_current_batch === true &&
            $this.singleStudent &&
            batch.value >= $this.batch.id) ||
          (!$this.singleStudent && batch.value > $this.batch.id)
        ) {
          return batch;
        }
      });
    },
    fromGrades() {
      let $this = this;
      let from = "";
      this.promote.from.grades.map(function(data) {
        if (parseInt($this.promote.from.grade) === parseInt(data.value)) {
          from = "," + data.text;
        }
      });
      return from;
    },
    fromSection() {
      let $this = this;
      let from = "";
      this.promote.from.sections.map(function(data) {
        if ($this.promote.from.section_id === data.value) {
          from = "(" + data.text + ")";
        }
      });
      return from;
    },
    toBatch() {
      let $this = this;
      let to = "";
      this.batches.map(function(data) {
        if (parseInt($this.promote.to.batch_id) === parseInt(data.value)) {
          to = data.text;
        }
      });
      return to;
    },
    selectedToBatch() {
      let $this = this;
      let to = "";

      this.batches.map(function(data) {
        if (parseInt($this.promote.to.batch_id) === parseInt(data.value)) {
          to = data;
        }
      });
      return to;
    },
    toGradeItems() {
      let $this = this;
      let fromGrade = this.getGradeByValue(this.promote.from.grade);
      let grades = this.promote.to.grades.filter((item) => {
        if ($this.isDemote && item.rank < fromGrade.rank) {
          return item;
        } else if (
          (!$this.isDemote &&
            $this.singleStudent &&
            item.rank > fromGrade.rank) ||
          (!$this.singleStudent && item.rank > fromGrade.rank)
        ) {
          return item;
        }
      });

      if ($this.isDemote && grades.length) {
        return [grades[grades.length - 1]];
      } else if (grades.length) {
        if (this.selectedToBatch.is_hs) {
          return grades;
        } else {
          return [grades[0]];
        }
      }
      return [];
    },
    toGrades() {
      let $this = this;
      let to = "";
      this.promote.to.grades.map(function(data) {
        if ($this.promote.to.grade == data.value) {
          to = "," + data.text;
        }
      });
      return to;
    },
    toSection() {
      let $this = this;
      let to = "";
      this.promote.to.sections.map(function(data) {
        if ($this.promote.to.section_id == data.value) {
          to = "(" + data.text + ")";
        }
      });
      return to;
    },
  },
  mounted() {
    this.getBatches();
    if (!this.grade_request_sent) this.getGrades();
    this.get();
    if (this.student) {
      this.promote.from.grade = parseInt(this.student.data.grade_id);
      this.promote.from.section_id = parseInt(this.student.data.section_id);
      this.selected = [
        {
          student_id: this.student.data.id,
          name: this.student.data.name,
          section_id: this.student.data.section_id,
          section: this.student.data.section,
          roll: this.student.data.roll,
          enroll_id: this.student.data.enroll_id,
        },
      ];
    }
  },
  methods: {
    getGradeByValue(value) {
      let grade = "";
      this.promote.from.grades.map(function(item) {
        if (value == item.value) {
          grade = item;
        }
      });
      return grade;
    },

    get(params) {
      let extraParam =
        "oldSectionId=" +
        this.promote.from.section_id +
        "&newSectionId=" +
        this.promote.to.section_id;
      let query = this.queryString(extraParam);
      if (this.promote.from.section_id && this.promote.to.section_id) {
        this.form.get(null, query).then(({ data }) => {
          if (data.newEnrolls) {
            this.newEnrolls = data.newEnrolls;
          }
        });
      }
    },
    getGrades() {
      // if (!this.grade_request_sent) {
      this.grade_request_sent = true;
      // this.promote.to.sections = [];
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true"
        )
        .then(({ data }) => {
          this.promote.from.grades = data.data.map((item) => {
            return { value: item.id, text: item.name, rank: item.rank };
          });
          this.grade_request_sent = false;
        });
      // }
    },
    getPromoteToGrades() {
      this.promote.to.grades = [];
      this.promote.to.grade = "";
      this.$rest
        .get(
          "/api/grades?slim=true&rowsPerPage=25&sortBy=rank&descending=false&batchId=" +
            this.promote.to.batch_id
        )
        .then(({ data }) => {
          this.promote.to.grades = data.data.map(function(item) {
            return { value: item.id, text: item.name, rank: item.rank };
          });
        });
    },
    getSections() {
      //empty the selected section_id of the promote only if this component is
      //not being used to promote single student
      if (!this.singleStudent) {
        this.promote.from.section_id = "";
        this.promote.from.sections = [];
      }
      this.$rest
        .get("/api/section?" + "grade=" + this.promote.from.grade)
        .then(({ data }) => {
          this.promote.from.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    getPromoteToSections() {
      this.promote.to.section_id = "";
      this.promote.to.sections = [];
      this.$rest
        .get(
          "/api/section?" +
            "grade=" +
            this.promote.to.grade +
            "&batchId=" +
            this.promote.to.batch_id
        )
        .then(({ data }) => {
          this.promote.to.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    getBatches() {
      //check if the batches was already fetched.
      this.$rest.get("/api/batch").then(({ data }) => {
        this.batches = data.data.map((item) => {
          return {
            value: item.id,
            text: item.name,
            is_current_batch: item.is_current_batch,
            is_hs: item.is_hs,
          };
        });
      });
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.form.items.data.slice();
    },

    promoteStudents() {
      this.form.new_section_id = this.promote.to.section_id;
      this.form.old_section_id = this.promote.from.section_id;
      this.form.students = this.selected.map(function(std) {
        return {
          roll: std.roll,
          student_id: std.student_id,
          enroll_id: std.enroll_id,
        };
      });

      if (this.isDemote) {
        this.$rest.post("/api/demote", this.form.data()).then((res) => {
          this.get();
          this.selected = [];
          if (this.singleStudent) this.$emit("onPromote");
          this.$events.fire(res.data.message);
        });
      } else {
        this.form.store().then((response) => {
          this.get();
          this.selected = [];
          if (this.singleStudent) this.$emit("onPromote");
          this.$events.fire(res.data.message);
        });
      }
    },
  },
};
</script>
<style lang="scss">
.user_add_form {
  .v-chip,
  .v-chip .v-chip__content {
    border-radius: 2px;
    color: white;
  }
}

.v-timeline--dense:before {
  left: 18px !important;
}

.v-timeline-item__dot.v-timeline-item__dot--small {
  margin-left: 0px !important;
}
</style>
